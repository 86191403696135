import React, { useState, useEffect, useRef } from 'react';
import API from '../API'
import axios from 'axios';
import styled from 'styled-components'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Popover, PopoverTrigger, PopoverContent } from '@radix-ui/react-popover';
import { useNavigate } from 'react-router-dom';
import './PopoverStyles.css'
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Chip } from 'primereact/chip';
import { renderBoolean } from './LeadDeliveryTable';
import { Dropdown } from 'primereact/dropdown';
import { Row } from 'primereact/row';
import { FilterMatchMode } from 'primereact/api';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';

import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';

const shouldIncludeFilter_isActive = (pageFilter = null) => {
    if (!pageFilter)
        return true
    if (pageFilter.value !== null)
        return false
    else
        return true
}

const getDefaultFilter = (pageFilter = null) => {
    const defaultFilterObj = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    }
    // if (shouldIncludeFilter_isActive(pageFilter))
    console.log("pageFilter", pageFilter)
    defaultFilterObj['isActive'] = { value: !pageFilter ? null : pageFilter.value, matchMode: FilterMatchMode.EQUALS }
    return defaultFilterObj
}

const AttorneysTableNew = ({ pageFilter = null }) => {
    console.log("isActive", pageFilter)
    const toast = useRef(null);
    const [regions, setRegions] = useState([]);
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();
    const [totalRecords, setTotalRecords] = useState(0);
    const [rows, setRows] = useState(10);
    const [first, setFirst] = useState(0);
    const debounceTimeout = useRef(null);
    const [batchIsActive, setBatchIsActive] = useState(null);
    const [shouldEmail, setShouldEmail] = useState(null);
    const [filters, setFilters] = useState(getDefaultFilter(pageFilter));
    console.log("filters")
    console.log(filters)
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [statuses] = useState(['unqualified', 'qualified', 'new', 'negotiation', 'renewal']);
    const activeStates = [
        // { name: '-', value: null },
        { name: 'Active', value: true },
        { name: 'Inactive', value: false }
    ];
    const emailStates = [
        // { name: '-', value: null },
        { name: 'Auto Email ON', value: true },
        { name: `Auto Email OFF`, value: false }
    ];
    const [selectedRecords, setSelectedRecords] = useState([]);
    const fetchClients = async (page, limit) => {
        try {
            let response = await API.getAttorneysNew(page, limit, filters)
            setRegions(response.data.clients);
            setTotalRecords(response.data.total);
        } catch (error) {
            console.error('Error fetching clients:', error);
        }
    };
    useEffect(() => {
        setFilters(getDefaultFilter(pageFilter))
    }, [pageFilter])

    useEffect(() => {

        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }

        debounceTimeout.current = setTimeout(() => {
            fetchClients(first / rows + 1, rows);
        }, 300); // Adjust the debounce delay as needed

        return () => clearTimeout(debounceTimeout.current);
    }, [first, rows, filters]);


    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;
        console.log("_filters", _filters)
        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const renderHeader = () => {
        return (
            <div className="flex justify-content-end">
                <IconField iconPosition="left">
                    <InputIcon className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
                </IconField>
            </div>
        );
    };

    const header = renderHeader();
    const verifiedRowFilterTemplate = (options) => {
        console.log(options, options.value)
        return <TriStateCheckbox value={options.value} onChange={(e) => options.filterApplyCallback(e.value)} />;
    };
    const statusRowFilterTemplate = (options) => {
        return (
            <Dropdown value={options.value} options={statuses} onChange={(e) => options.filterApplyCallback(e.value)} itemTemplate={statusItemTemplate} placeholder="Select One" className="p-column-filter" showClear style={{ minWidth: '12rem' }} />
        );
    };

    const verifiedBodyTemplate = (rowData) => {
        return <i className={classNames('pi', { 'true-icon pi-check-circle': rowData.isActive, 'false-icon pi-times-circle': !rowData.isActive })} style={{ color: rowData.isActive ? 'green' : 'red' }}></i>;
    };

    const statusItemTemplate = (accessor, compareValue) => (rowData) => {
        const isTrue = rowData[accessor] === compareValue
        return (<i style={{ color: isTrue ? 'green' : 'red' }} className={classNames('pi', { 'true-icon pi-check-circle': isTrue, 'false-icon pi-times-circle': !isTrue })}></i>)
    }

    const renderDetails = (rowData) => {
        return (<>
            <Popover>
                <PopoverTrigger>Details</PopoverTrigger>
                <PopoverContent className='PopoverContent'>
                    <div>{(new Date(rowData.createdAt)).toLocaleDateString()}</div>
                    <span>Notes</span>
                    <div>{rowData.additionalDetails}</div>
                </PopoverContent>
            </Popover></>)
    }

    const renderRegionNames = (rowData) => {
        const regionArr = rowData.regions//.citiesAndCountiesString.concat(rowData.statesString)
        if (regionArr && regionArr.length > 0) {
            const firstRegion = regionArr[0];
            return (<>
                <Popover>
                    <PopoverTrigger>{firstRegion}</PopoverTrigger>
                    <PopoverContent className='PopoverContent'>
                        <div>{regionArr.map((region, index) => {
                            return ((
                                <Chip key={`${region}-${index}`} label={region} style={{
                                    fontSize: '10px',
                                    margin: '5px'
                                    // border: `1px solid ${color.dark}`,
                                    // color: color.dark,
                                    // backgroundColor: color.light
                                }}></Chip>
                            ))
                        })}</div>
                    </PopoverContent>
                </Popover></>)
            return (<div>{regionArr.map((region, index) => {
                return ((
                    <Chip key={`${region}-${index}-${rowData._id}`} label={region} style={{
                        fontSize: '10px',
                        // border: `1px solid ${color.dark}`,
                        // color: color.dark,
                        // backgroundColor: color.light
                    }}></Chip>
                ))
            })}</div>)
        } else if (rowData.regionId) {
            return rowData.regionId.name;
        }
        return null;
    };
    const renderPracticeAreas = (rowData) => {
        if (rowData.practiceAreas && rowData.practiceAreas.length > 0) {
            const firstRegion = rowData.practiceAreas[0];
            return (<div>{rowData.practiceAreas.map((practiceArea, index) => {
                return ((
                    <Chip key={`${practiceArea}-${index}`} label={practiceArea} style={{
                        fontSize: '10px',
                        // border: `1px solid ${color.dark}`,
                        // color: color.dark,
                        // backgroundColor: color.light
                    }}></Chip>
                ))
            })}</div>)
        }
        return null;
    };

    const handleRowClick = (rowData) => {
        console.log(rowData._id)
        // navigate(`/geographic-region/${rowData._id}`);
    };

    const onPageChange = (event) => {
        setFirst(event.first);
        setRows(event.rows);
    };
    const handleBulkUpdate = async () => {
        const arrOfIds = selectedRecords.map(it => it._id)
        let updateObject = {}
        // console.log("shouldEmail", shouldEmail, batchIsActive)
        if (batchIsActive !== null) {
            updateObject['isActive'] = batchIsActive
        }
        if (shouldEmail !== null) {
            updateObject['autoEmail'] = shouldEmail
        }
        // return
        if (Object.keys(updateObject).length > 0) {
            try {
                const res = await API.updateAttorneyRecords(arrOfIds, updateObject, first / rows + 1, rows)
                console.log("res", res)
                toast.current.show({ severity: 'success', summary: 'Success', detail: 'Record(s) updated successfully' });
                setBatchIsActive(null)
                setShouldEmail(null)
                setSelectedRecords([])
                setRegions(res.data.clients)
            } catch (error) {
                console.log(error)
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Error updating record(s)' });
            }


        }
    }

    // const headerGroup = (
    //     <ColumnGroup>

    //         {/* <Row> */}
    //         <Column header="Client" rowSpan={2} />
    //         <Column header="Contact" colSpan={2} />
    //         <Column header="Practice Area" colSpan={2} />
    //         <Column header="Enabled" colSpan={1} rowSpan={2} />
    //         <Column header="Override Time Windows" colSpan={1} rowSpan={2} />
    //         <Column header="Days" colSpan={1} rowSpan={2} />
    //         <Column header="View" colSpan={1} rowSpan={2} />
    //         {/* </Row> */}
    //         {/* <Row>
    //             <Column header="Sales" colSpan={2} />
    //             <Column header="Profits" colSpan={2} />
    //         </Row> */}
    //         {/* <Row> */}
    //         <Column header="Included" field="lastYearSale" />
    //         <Column header="Excluded" field="thisYearSale" />
    //         <Column header="Included" field="lastYearProfit" />
    //         <Column header="Excluded" field="thisYearProfit" />
    //         {/* </Row> */}
    //     </ColumnGroup>
    // );
    return (
        <PageContentContainer className="geographic-regions-table">
            <Toast ref={toast} />
            <h2>Injury Lead Gen Clients</h2>
            <div style={{ background: '' }}>
                <FieldContainer className="card flex flex-column justify-content-center">
                    <label htmlFor='isActive'>Active (Set to active if there's an attorney/geographic region record)</label>
                    <Dropdown
                        id="isActive"
                        value={batchIsActive} onChange={(e) => setBatchIsActive(e.value)} options={activeStates} optionLabel="name"
                        placeholder="Client Status" className="w-full md:w-14rem" showClear />
                    <label htmlFor="shouldEmail">Should Email</label>
                    <Dropdown
                        id="shouldEmail" value={shouldEmail} onChange={(e) => setShouldEmail(e.value)} options={emailStates} optionLabel="name" showClear
                        placeholder="Email Status" className="w-full md:w-14rem" />
                    <Button
                        label="Update"
                        onClick={handleBulkUpdate}
                        style={{
                            backgroundColor: "#b1eccb",
                            padding: '10px',
                            cursor: 'pointer',
                            margin: '8px'
                        }}
                    />
                </FieldContainer>

            </div>

            <TableContainer className="card">
                <DataTable
                    onFilter={(e) => {
                        console.log("filter change")
                        console.log(e)
                        setFilters(e.filters)
                    }}
                    filters={filters}
                    filterDisplay="row"
                    loading={loading}
                    globalFilterFields={['name', 'country.name', 'representative.name', 'status']}
                    selection={selectedRecords}
                    header={header}
                    onSelectionChange={(e) => {
                        setSelectedRecords(e.value)
                        // if (e.value.length === 1) {
                        //     let index = selectedRecords.findIndex(it => it._id === e.value[0]._id)
                        //     console.log("index", index, e.value)
                        //     let _selectedRecords = selectedRecords
                        //     if (index === -1)
                        //         setSelectedRecords(e.value)
                        //     else {
                        //         _selectedRecords.splice(index, 1)
                        //         setSelectedRecords(_selectedRecords)
                        //     }
                        // }
                        // else {

                        //     setSelectedRecords(e.value)
                        // }
                    }}
                    value={regions}
                    // selectionMode="checkbox"
                    dataKey={"_id"}
                    // onRowClick={(e) => handleRowClick(e.data)}
                    paginator={true}
                    rows={rows}
                    totalRecords={totalRecords}
                    first={first}
                    lazy={true}
                    onPage={onPageChange}>

                    <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                    <Column field="firmName" header="Firm" />
                    <Column field="user.name" header="Name" />
                    {/* <Column field="user.email" header="Email" /> */}
                    <Column header="Contact"
                        body={(rowData) => {
                            console.log()
                            return (
                                <>
                                    <div>{rowData.user?.email}</div>
                                    <div style={{ fontSize: '10px' }}>{rowData.phone}</div>
                                </>
                            )
                        }}
                    />
                    {/* <Column field="phone" header="Phone" /> */}
                    {/* <Column field="clientType" header="Type" /> */}
                    <Column body={renderBoolean('autoEmail', true)} header="Auto Email" />
                    {/* <Column header="Pricing">
                    {(rowData) => (
                        <>
                            <div>{rowData.price}</div>
                            <div>{rowData.pricingType}</div>
                        </>
                    )}
                </Column> */}
                    {/* <Column field="regionalBudget" header="Regional Budget" /> */}
                    <Column header="Practice Areas" body={renderPracticeAreas} />
                    <Column header="Region Names" body={renderRegionNames} />
                    <Column field="isActive"
                        header="Active Client"
                        dataType="boolean" style={{ minWidth: '6rem' }} body={verifiedBodyTemplate}
                        filter={shouldIncludeFilter_isActive(pageFilter)}
                        filterElement={shouldIncludeFilter_isActive(pageFilter) ? verifiedRowFilterTemplate : undefined} />
                    <Column body={renderDetails}
                        header="Details" />
                    {/* <Column
                    dataType="boolean"
                    filter filterElement={statusRowFilterTemplate}
                    header={<div>

                        <span>Active</span>
                        <div className="card flex justify-content-center">
                            <Dropdown value={batchIsActive} onChange={(e) => setBatchIsActive(e.value)} options={activeStates} optionLabel="name"
                                placeholder="Bulk Set Status" className="w-full md:w-14rem" />
                        </div>
                    </div>}
                    body={renderBoolean('isActive', true)} /> */}
                </DataTable>
            </TableContainer>
        </PageContentContainer>
    );
};

const PageContentContainer = styled.div`
display: flex;
flex-direction: column;
width: auto;
position: relative;
height: 100%;
max-height: 100vh;
overflow: auto;
.p-paginator-element{
    width: 2em;
    padding: 10px;
    border-radius: 5px;
    margin: 2px;
}
.p-paginator-page{
    background: #c7fae6;
    cursor: pointer;
    color: #9f9f9f;
}
.p-paginator-page.p-highlight{
    color: black;
    background: #00ff99;
}
`;

const TableContainer = styled.div`
position: relative;
max-width: calc(100vw - 120px);
`;

const FieldContainer = styled.div`
background: #f2f2f2;
margin: auto;
display: grid;
grid-template-columns: 1fr;
width: fit-content;
grid-auto-flow: row;
grid-auto-rows: auto;
padding: 10px;
label {
    width: fit-content;
}
.p-dropdown{
    max-width: 200px;
}
`;

export default AttorneysTableNew;
