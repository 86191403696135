import React, { useState, useEffect, useRef } from 'react';
import API from '../API'
import { useParams, useNavigate } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { Toast } from 'primereact/toast';
import { Message } from 'primereact/message';
import { Tag } from 'primereact/tag';
import { Chip } from 'primereact/chip';
import styled from 'styled-components';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Dropdown } from 'primereact/dropdown';
import { formatPrice } from '../utils';

const Container = styled.div`
display: grid;
grid-template-columns: 1fr;
grid-template-rows: auto;
grid-auto-flow: row;
grid-auto-rows: auto;
gap: 1rem;
padding: 1em 5em;
width: 100%;
max-width: 500px;
margin: auto;
background-color: #f2f2f2;
margin: 2em auto;
h3, h5, p{
    margin: 0;
}
`;

const InnerContainer = styled(Container)`
background-color: ${({ bgd }) => bgd};
/* padding: 0; */
`;

const SimpleDiv = styled.div`
max-width: 500px;
padding: .5em 5em 1em;
background-color: #f2f2f2;
margin: auto;
`;


const GridC = styled.div`
display: grid;
grid-template-columns: 1fr 1fr;
background-color: #f2f2f2;
max-width: 500px;
margin: auto;
div{
    border: 1px solid #b8b7b7f1;
    padding: 1rem;
}
div:nth-of-type(2n+1){
    text-align: right;
    color: #1b202b;
    font-weight: bold;
}
div:nth-of-type(2n){
    text-align: left;
    color: #3b4251;
}
`;

const GeographicRegionDetail = () => {
    const { id } = useParams();
    console.log("id:::",id)
    const navigate = useNavigate();
    const [region, setRegion] = useState(null);
    const [loading, setLoading] = useState(true);
    const toast = useRef(null);

    const [newExcludedHitBy, setNewExcludedHitBy] = useState('');
    const [newExcludedInjuryTypeDetailed, setNewExcludedInjuryTypeDetailed] = useState('');
    const [newExcludedLeadKinds, setNewExcludedLeadKinds] = useState('');
    const [leadKinds, setNewLeadKinds] = useState('');
    const [newExcludedAccidentRole, setNewExcludedAccidentRole] = useState('');

    const [newBudgetValue, setNewBudgetValue] = useState(0);
    const [budgetNotes, setBudgetNotes] = useState('');
    const [updateType, setUpdateType] = useState(null);
    const updateTypes = ['GhostBudget', 'BudgetIncrease', 'AdminIncrease', 'BudgetShift', 'DisputeReimbursal'];


    const accidentRoleOptions = ["Car Driver", "Ride-Share Passenger (Uber, Lyft, taxi, etc.)", "Car Passenger", "Pedestrian", "Cyclist",
        "Motorcycle Rider", "Mass Transit Passenger"]

    const hitByOptions = ["Bus", "Car", "Commercial Vehicle/Truck/18-Wheeler", "Ride-Share Driver", "My vehicle hit a stationary structure (such as a barrier)",]
    const injuryTypeDetailedOptions = ['Commercial Vehicle Accident',
        'Uber Accident',
        'Lyft Accident',
        'Bus Accident',
        'Pedestrian Accident',
        'Motorcycle Accident',
        'Bicycle Accident',
        'Slip and Fall Accident',
        'UPS Accident',
        'FedEx Accident']

    const leadKindOptions = ['SettlementCalcLead', 'AccidentReport','LawyerMonkeyLead']

    useEffect(() => {
        async function getGeoRegion() {
            let data = await API.getGeographicRegion(id)
            if (data)
                setRegion(data)
            setLoading(false)
        }
        getGeoRegion()
    }, [id]);

    const handleInputChange = (e, field) => {
        const value = (e.target && e.target.value) || e.value || e.checked;
        setRegion({ ...region, [field]: value });
    };

    const handleAddBudget = async () => {
        const budgetUpdate = {
            recordId: region._id,
            recordType: 'GeographicRegion',
            budgetBefore: region.regionalBudget,
            budgetAfter: region.regionalBudget + newBudgetValue,
            valueAdded: newBudgetValue,
            updateType,
            notes: budgetNotes,
        };
        try {
            let response = await API.updateGeographicRegion(budgetUpdate)
            setRegion({ ...region, regionalBudget: response.data.budgetAfter });
            toast.current.show({ severity: 'success', summary: 'Success', detail: 'Budget updated successfully' });
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Error updating budget' });
            console.error('Error updating budget:', error);
        }
    };


    const handleRequirementChange = (e, field, subfield, index = null) => {
        const value = e.target ? e.target.value : e.checked;
        const updatedRequirements = { ...region.requirements };

        if (index !== null) {
            updatedRequirements[field][index] = value;
        } else {
            updatedRequirements[field] = value;
        }

        setRegion({ ...region, requirements: updatedRequirements });
    };

    const handleAddRequirement = (field, value) => {
        const updatedRequirements = { ...region.requirements };
        updatedRequirements[field].push(value);
        setRegion({ ...region, requirements: updatedRequirements });
    };

    const handleRemoveRequirement = (field, index) => {
        const updatedRequirements = { ...region.requirements };
        updatedRequirements[field].splice(index, 1);
        setRegion({ ...region, requirements: updatedRequirements });
    };

    const handleSave = async () => {
        try {
            let response = await API.geoRegion__handleSave(region, id)
            toast.current.show({ severity: 'success', summary: 'Success', detail: 'Region updated successfully' });
        } catch (error) {
            console.error('Error updating region:', error);
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        < div style={{ maxWidth: '700px', margin: 'auto' }}>
            <Toast ref={toast} />
            <h2>Geographic Region Details</h2>
            <div className="p-fluid p-grid">
                <GridC >
                    <div>Firm Name</div>
                    <div>
                        {
                            region.attorneyId.firmName
                        }
                    </div>
                </GridC>
                <GridC >
                    <div>Region</div>
                    {(region?.regionIds && region.regionIds.length > 0) &&
                        <div>
                            {
                                region.regionIds.map(_region => {
                                    return (<>{_region.regionId.name}</>)
                                })
                            }
                        </div>
                    }
                    {region?.regionId &&
                        <div>
                            {region.regionId.name}
                        </div>
                    }
                </GridC>
                <GridC >
                    <div>Practice Area</div>
                    <div>
                        {
                            region.practiceAreaId.name
                        }
                    </div>
                </GridC>
                {
                    (region?.excludedPracticeAreas && region?.excludedPracticeAreas?.length > 0) && <GridC >
                        <div>Excluded Practice Areas</div>
                        <div>
                            {
                                region?.excludedPracticeAreas?.map(it => <>{it.name}</>)
                            }
                        </div>
                    </GridC>
                }
                <GridC >
                    <div># Leads</div>
                    <div>
                        {
                            region.nbLeadsRegion
                        }
                    </div>
                </GridC>
                <GridC >
                    <div>Regional Total Spent</div>
                    <div>
                        {
                            formatPrice(region.regionalTotalSpent)
                        }
                    </div>
                </GridC>
                <GridC >
                    <div>Last Updated</div>
                    <div>
                        {
                            new Date(region.updatedAt).toUTCString()
                        }
                    </div>
                </GridC>
                {
                    region.pricingType === 'FlexPricing' && <>
                        <GridC >
                            <div>Pricing Type</div>
                            <div>Flex Pricing</div>
                        </GridC></>
                }
            </div>
            <Container className="p-fluid p-grid">
                <h3>Budget Update</h3>
                <div className="p-field p-col-12">
                    <label htmlFor="regionalBudget">Regional Budget</label>
                    <InputNumber id="regionalBudget" value={region.regionalBudget} mode="currency" currency="USD" disabled />
                </div>
                <div className="p-field p-col-12">
                    <label htmlFor="newBudgetValue">Add to Budget</label>
                    <InputNumber id="newBudgetValue" value={newBudgetValue} onValueChange={(e) => setNewBudgetValue(e.value)} mode="currency" currency="USD" />
                </div>
                <div className="p-field p-col-12">
                    <label htmlFor="updateType">Update Type</label>
                    <Dropdown id="updateType" value={updateType} options={updateTypes} onChange={(e) => setUpdateType(e.value)} placeholder="Select an Update Type" />
                </div>
                <div className="p-field p-col-12">
                    <label htmlFor="budgetNotes">Notes</label>
                    <InputTextarea id="budgetNotes" value={budgetNotes} onChange={(e) => setBudgetNotes(e.target.value)} rows={3} cols={30} />
                </div>
                <div className="p-field p-col-12">
                    <Button label="Add Budget" icon="pi pi-plus" onClick={handleAddBudget} />
                </div>
            </Container>
            <Container className="p-fluid p-grid">
                <div className="p-field p-col-12">
                    <label htmlFor="price">Price</label>
                    <InputNumber id="price" value={region.price} onValueChange={(e) => handleInputChange(e, 'price')} mode="currency" currency="USD" />
                </div>
                {/* <div className="p-field p-col-12">
                    <label htmlFor="regionalBudget">Regional Budget</label>
                    <InputNumber id="regionalBudget" value={region.regionalBudget} onValueChange={(e) => handleInputChange(e, 'regionalBudget')} mode="currency" currency="USD" />
                </div> */}

                <div className="p-field p-col-12">
                    <label htmlFor="isActive">Active</label>
                    <Checkbox inputId="isActive" checked={region.isActive} onChange={(e) => handleInputChange(e, 'isActive')} />
                </div>
                <div className="p-field p-col-12">
                    <label htmlFor="clientMatchPriority">Client Match Priority</label>
                    <p style={{ fontSize: '12px' }}>Lower Number = Higher Priority<br />Client with priority "1" receives lead over client with priority "100"</p>
                    <InputNumber id="clientMatchPriority" value={region.clientMatchPriority} onValueChange={(e) => handleInputChange(e, 'clientMatchPriority')} />
                </div>

            </Container>
            <InnerContainer bgd="#fc9292">
                <h5>Exclusions</h5>
                <p>Leads with values set for the following won't be sent to the client.</p>
                <div className="p-field p-col-12">
                    <label htmlFor="excludedHitBy">Excluded Hit By</label>
                    <div className="p-inputgroup">
                        <Dropdown value={newExcludedHitBy} options={hitByOptions} onChange={(e) => setNewExcludedHitBy(e.value)} placeholder="Hit By" />
                        <InputText id="excludedHitBy" value={newExcludedHitBy} onChange={(e) => setNewExcludedHitBy(e.target.value)} />
                        <Button label="Add" icon="pi pi-plus" onClick={() => { handleAddRequirement('excludedHitBy', newExcludedHitBy); setNewExcludedHitBy(''); }} />
                    </div>
                    <div>
                        {region.requirements.excludedHitBy.map((item, index) => (
                            <Chip label={item} key={index} removable onRemove={() => handleRemoveRequirement('excludedHitBy', index)} />
                        ))}
                    </div>
                </div>
                <div className="p-field p-col-12">
                    <label htmlFor="excludedInjuryTypeDetailed">Excluded Injury Type Detailed</label>
                    <div className="p-inputgroup">
                        <Dropdown value={newExcludedInjuryTypeDetailed} options={injuryTypeDetailedOptions} onChange={(e) => setNewExcludedInjuryTypeDetailed(e.value)} placeholder="Select an Injury Type" />
                        <InputText id="excludedInjuryTypeDetailed" value={newExcludedInjuryTypeDetailed} onChange={(e) => setNewExcludedInjuryTypeDetailed(e.target.value)} />
                        <Button label="Add" icon="pi pi-plus" onClick={() => { handleAddRequirement('excludedInjuryTypeDetailed', newExcludedInjuryTypeDetailed); setNewExcludedInjuryTypeDetailed(''); }} />
                    </div>
                    <div>
                        {region.requirements.excludedInjuryTypeDetailed.map((item, index) => (
                            <Chip label={item} key={index} removable onRemove={() => handleRemoveRequirement('excludedInjuryTypeDetailed', index)} />
                        ))}
                    </div>
                </div>
                <div className="p-field p-col-12">
                    <label htmlFor="excludedAccidentRole">Excluded Accident Role</label>
                    <div className="p-inputgroup">
                        <Dropdown value={newExcludedAccidentRole} options={accidentRoleOptions} onChange={(e) => setNewExcludedAccidentRole(e.value)} placeholder="Accident Role" />
                        <InputText id="excludedAccidentRole" value={newExcludedAccidentRole} onChange={(e) => setNewExcludedAccidentRole(e.target.value)} />
                        <Button label="Add" icon="pi pi-plus" onClick={() => { handleAddRequirement('excludedAccidentRole', newExcludedAccidentRole); setNewExcludedAccidentRole(''); }} />
                    </div>
                    <div>
                        {region.requirements.excludedAccidentRole.map((item, index) => (
                            <Chip label={item} key={index} removable onRemove={() => handleRemoveRequirement('excludedAccidentRole', index)} />
                        ))}
                    </div>
                </div>
                <div className="p-field p-col-12">
                    <label htmlFor="excludedLeadKinds">Excluded Lead Kind/Source</label>
                    <div className="p-inputgroup">
                        <Dropdown value={newExcludedLeadKinds} options={leadKindOptions} onChange={(e) => setNewExcludedLeadKinds(e.value)} placeholder="Lead Kind" />
                        <Button label="Add" icon="pi pi-plus" onClick={() => { handleAddRequirement('excludedLeadKinds', newExcludedLeadKinds); setNewExcludedLeadKinds(''); }} />
                    </div>
                    <div>
                        {region.excludedLeadKinds.map((item, index) => (
                            <Chip label={item} key={index} removable onRemove={() => handleRemoveRequirement('excludedLeadKinds', index)} />
                        ))}
                    </div>
                </div>
            </InnerContainer>
            <InnerContainer bgd="#bfedd2">
                <h5>Qualifier Requirements</h5>
                <p>Leads *must* match values specified below to be sent to the client.</p>
                <div className="p-field p-col-12">
                    <label htmlFor="leadKinds">Lead Kind/Source</label>
                    <div className="p-inputgroup">
                        <Dropdown value={leadKinds} options={leadKindOptions} onChange={(e) => setNewLeadKinds(e.value)} placeholder="Lead Kind" />
                        <Button label="Add" icon="pi pi-plus" onClick={() => { handleAddRequirement('leadKinds', leadKinds); setNewLeadKinds(''); }} />
                    </div>
                    <div>
                        {region.leadKinds.map((item, index) => (
                            <Chip label={item} key={index} removable onRemove={() => handleRemoveRequirement('leadKinds', index)} />
                        ))}
                    </div>
                </div>
                <div className="p-field p-col-12">
                    <label htmlFor="zipCodeRequired">Zip Code Required</label>
                    <Checkbox inputId="zipCodeRequired" checked={region.requirements.zipCodeRequired} onChange={(e) => handleRequirementChange(e, 'zipCodeRequired')} />
                </div>
            </InnerContainer>
            <ButtonContainer >
                <Button label="Save" icon="pi pi-check" onClick={handleSave} />
                <Button label="Cancel" icon="pi pi-times" className="p-button-secondary" onClick={() => navigate('/geographic-regions')} />
            </ButtonContainer>
            <Container >
                <h3>Contact Methods</h3>
                <h5>CRM</h5>
                {(region.contactMethods?.crm && region.contactMethods.crm.length === 0) && <>-</>}
                {
                    region.contactMethods?.crm.map(it => {
                        const { name, url } = it
                        return (
                            <div style={{
                                backgroundColor: '#9ee6bd',
                                padding: '5px',
                                border: '1px solid #29a35e',
                                width: 'fit-content',
                                borderRadius: '3px',
                                margin: 'auto'
                            }}>
                                <h5 style={{ margin: '0' }}>{name}</h5>
                                <p style={{ margin: '0', fontSize: '11px' }}>{url}</p>
                            </div>)
                    })
                }
                <h5>Email List</h5>
                {(region.contactMethods?.emailList && region.contactMethods.emailList.length === 0) && <>-</>}
                {
                    region.contactMethods?.emailList.map(it => {
                        return (
                            <div>{
                                it
                            }
                            </div>)
                    })
                }
            </Container>
        </div>
    );
};

const ButtonContainer = styled.div`
display: grid;
grid-template-columns: auto auto;
gap: 2rem;
button{
    max-width: 100px;
}
button:first-of-type{
    justify-self: end;
}
`;

export default GeographicRegionDetail;
