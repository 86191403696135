import React, { useState, useEffect } from 'react';
import API from '../API'
import axios from 'axios';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Popover, PopoverTrigger, PopoverContent } from '@radix-ui/react-popover';
import { useNavigate } from 'react-router-dom';

import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import './PopoverStyles.css';
import Toggle from './Toggle';
import { Chip } from 'primereact/chip';
import TrueIcon from '../images/acceptIcon.svg'
import FalseIcon from '../images/close.svg'
import Icon from './Icon';
import classNames from 'classnames';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';

export const renderBoolean = (accessor, compareValue) => (rowData) => {
    const isTrue = rowData[accessor] === compareValue
    return (<i style={{ color: isTrue ? 'green' : 'red' }} className={classNames('pi', { 'true-icon pi-check-circle': isTrue, 'false-icon pi-times-circle': !isTrue })}></i>)
}

const LeadDeliveryTable = () => {
    const [configs, setConfigs] = useState([]);
    const navigate = useNavigate();
    const daysMap = ['Su', 'M', 'T', 'W', 'R', 'F', 'Sa'];
    useEffect(() => {
        async function getGeoRegions() {
            try {
                let response = await API.getAllAutoSendConfigs()
                setConfigs(response.data.configs);
            } catch (error) {
                console.error('Error fetching configs:', error);
            }
        }
        getGeoRegions()
    }, []);
    console.log("configs", configs)
    const renderDayNames = (rowData) => {
        console.log("rowData", rowData)
        if (rowData.timeWindows)
            return (<>
                {
                    rowData.timeWindows.map(it => {
                        return (<>{it.start}-{it.end}<br />{it.days.map(day => daysMap[day]).join(';')}</>)
                    })
                }

            </>);
    };
    const getRegionLabel = (region) => {
        if (region.regionType === 'State')
            return region.regionId.abbreviation
        else
            return `${region.regionName}, ${region.regionId.ste_name}`
    }
    const renderRegionNames = (accessor = 'regionIds') => (rowData) => {
        if (rowData[accessor] && rowData[accessor].length > 0) {
            const firstRegion = rowData[accessor][0];
            const color = accessor.includes('excluded') ? {
                dark: '#640b0b',
                light: '#fbc0bc'
            } : { dark: '#0b4d2afa',
                light: '#bcfbbf' }
            return (<div>{rowData[accessor].map((region, index) => {
                return ((
                    <Chip key={index} label={getRegionLabel(region)} style={{
                        fontSize: '10px',
                        border: `1px solid ${color.dark}`,
                        color: color.dark,
                        backgroundColor: color.light
                    }}></Chip>
                ))
            })}</div>)
            // return (
            //     <Popover>
            //         <PopoverTrigger asChild className="PopoverTrigger">
            //             <Button label={getRegionLabel(firstRegion)} />
            //         </PopoverTrigger>
            //         <PopoverContent className="PopoverContent" >
            //             <ul>
            //                 {rowData[accessor].map((region, index) => {
            //                     return ((
            //                         <li key={index}>{getRegionLabel(region)}</li>
            //                     ))
            //                 })}
            //             </ul>
            //         </PopoverContent>
            //     </Popover>
            // );
        } else if (rowData.regionId) {
            return rowData.regionId.name;
        }
        return null;
    };
    const renderPracticeArea = (practiceArea) => {
        switch (practiceArea) {
            case "Commercial Vehicle Accident":
                return "🚛"
            case "Worker's Compensation":
                return "💼"
            case "Auto Accident":
                return "🚗"
            case "Product Liability":
                return "🧴"
            case "Premises Liability/Slip and Fall":
                return "🚧"
            case "Other":
                return "❓"
            default:
                return "🩹"
        }
    }

    

    const renderPracticeAreaIcons = (accessor = 'practiceAreaIds') => (rowData) => {
        if (rowData[accessor] && rowData[accessor].length > 0) {
            const firstRegion = rowData[accessor][0];
            return (
                <>
                    {/* <PopoverContent> */}
                    {/* <ul> */}
                    {rowData[accessor].map((region, index) => (
                        <span key={index} title={region.name}>{renderPracticeArea(region.name)}</span>
                    ))}
                    {/* </ul> */}
                    {/* </PopoverContent> */}
                </>
            );
        } else if (rowData.regionId) {
            return rowData.regionId.name;
        }
        return null;
    };

    const handleRowClick = (rowData) => {
        console.log("rowData", rowData)
        // navigate(`/lead-delivery/${rowData._id}`);
    };
    const renderEditButton = (rowData) => {

        return (<div onClick={() => navigate(`/lead-delivery/${rowData._id}`)}>Edit</div>)
    }
    const headerGroup = (
        <ColumnGroup>
            <Row>
                <Column header="Name" rowSpan={2} />
                <Column header="Geographic Region" colSpan={2} />
                <Column header="Practice Area" colSpan={2} />
                <Column header="Enabled" colSpan={1} rowSpan={2} />
                <Column header="Override Time Windows" colSpan={1} rowSpan={2} />
                <Column header="Days" colSpan={1} rowSpan={2} />
                <Column header="View" colSpan={1} rowSpan={2} />
            </Row>
            {/* <Row>
                <Column header="Sales" colSpan={2} />
                <Column header="Profits" colSpan={2} />
            </Row> */}
            <Row>
                <Column header="Included" field="lastYearSale" />
                <Column header="Excluded" field="thisYearSale" />
                <Column header="Included" field="lastYearProfit" />
                <Column header="Excluded" field="thisYearProfit" />
            </Row>
        </ColumnGroup>
    );
    return (
        <div className="geographic-regions-table">
            <h2>Lead Delivery</h2>
            <DataTable value={configs} onRowClick={(e) => handleRowClick(e.data)}
                onCellSelect={(e) => {
                    console.log(e)
                }} headerColumnGroup={headerGroup}>
                <Column header="Name" body={(rowData) => rowData?.name ? rowData.name : '-'} />
                <Column header="Included Regions" body={renderRegionNames('regionIds')} style={{ backgroundColor: '#e5f7de' }} />
                <Column header="Excluded Regions" body={renderRegionNames('excludedRegionIds')} style={{ backgroundColor: '#f7e4e4' }} />
                <Column header="Included PA" body={renderPracticeAreaIcons('practiceAreaIds')} style={{ backgroundColor: '#e5f7de' }} />
                <Column header="Excluded PA" body={renderPracticeAreaIcons('excludedPracticeAreaIds')} style={{ backgroundColor: '#f7e4e4' }} />
                {/* <Column header="Days" body={renderRegionNames('regionIds')} /> */}
                <Column
                    header="Enabled"
                    // field="isEnabled"
                    body={renderBoolean("isEnabled", true)}
                    onClick={(e) => {
                        e.preventDefault()
                        console.log("clicl", e.target)
                    }}
                // body={renderToggle}
                />
                <Column
                    body={renderBoolean("overrideTimeWindows", true)}
                    // field="overrideTimeWindows"
                    header="Override Time Windows" />
                <Column header="Days" body={renderDayNames} />
                <Column header="View" body={renderEditButton} />
            </DataTable>
            <Button onClick={async (e) => {
                let res = await API.addNewConfig()
                navigate(`/lead-delivery/${res.config._id}`)
            }}>Add New</Button>
        </div>
    );
};




const renderToggle = (rowData) => {
    return <div onClick={(e) => {
        console.log("ee")
        e.preventDefault()
        // e.target.preventDefault()
    }}><Toggle
            label="Enable Auto-Send"
            showLabelText={true}
            _checked={rowData.isEnabled}
            _setChecked={(e) => {

            }}
        /></div>
}


export default LeadDeliveryTable;
